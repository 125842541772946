import React,{useEffect,useState} from 'react';
import {
    Col,
    Card,
    CardImgOverlay,
    CardBody,
    CardImg,
    Row,
    Button
} from "reactstrap";
import Panel from "../../../../shared/components/Panel";
import {connect} from "react-redux";
import ModelProduct from '../../../../Models/Product';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import {setPriceHistoryRange} from "../../../../redux/actions/themeActions";
Chart.register(...registerables);



const PriceHistory = ({dispatch, priceTrackerData,productData,priceHistoryRange,ratingHistoryRange}) => {

    const mv = new ModelProduct();
    const [data,setData] = useState(false);

    useEffect(()=>{

        let linePoints=[];
        let xaxis = [];
        if(priceTrackerData!== false){
            priceTrackerData.forEach((price)=>{
                linePoints.push(price.pt_amount);
                xaxis.push(price.pt_created);
            });
        }
        setData({
            labels: xaxis,
            datasets: [
                {
                    label: 'Amazon',
                    fill: false,
                    lineTension: 0.3,
                    backgroundColor: '#FFCE56',
                    borderColor: '#FFCE56',
                    borderWidth: 1,
                    pointBackgroundColor: '#FFCE56',
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 1,
                    pointRadius: 4,
                    pointHitRadius: 10,
                    data: linePoints,
                },
                // {
                //     label: 'Green',
                //     fill: false,
                //     lineTension: 0.3,
                //     backgroundColor: '#4BC0C0',
                //     borderColor: '#4BC0C0',
                //     borderWidth: 1,
                //     pointBackgroundColor: '#4BC0C0',
                //     pointHoverRadius: 6,
                //     pointHoverBorderWidth: 1,
                //     pointRadius: 5,
                //     pointHitRadius: 10,
                //     data: [52, 47, 45, 4, 58, 7, 45],
                // },
            ],
        });
    },[priceTrackerData]);

    const onDateRangeChange=(e)=>{
        dispatch(setPriceHistoryRange(e.target.value));
        mv.getProductData(productData.p_id,e.target.value,ratingHistoryRange).then(async response=>{
            // await setData(response.data.data);
        });
    };

    const options = {
        legend: {
            position: 'bottom',
        },
        scales: {
            xAxes: [
                {
                    type: "time",
                    time: {
                        unit: 'day',
                        unitStepSize: 2
                    },
                    gridLines: {
                        color: 'rgb(204, 204, 204)',
                        borderDash: [3, 3],
                    },
                    ticks: {
                        autoSkip: true,
                        fontColor: 'rgb(204, 204, 204)',
                    },
                },
            ],
            yAxes: [
                {
                    gridLines: {
                        color: 'rgb(204, 204, 204)',
                        borderDash: [3, 3],
                    },
                    ticks: {
                        autoSkip: true,
                        fontColor: 'rgb(204, 204, 204)',
                    },
                },
            ],
        },
    };

    return (
            data!== false &&
            <Card>
                <CardBody>
                    <div className="card__title" style={{display:"flex"}}>
                        <h5 className="bold-text mr-auto">Price History</h5>
                        <select onChange={onDateRangeChange}>
                            <option value="14">14 Days</option>
                            <option value="30">30 Days</option>
                            <option value="60">60 Days</option>
                            <option value="180">6 Months</option>
                            <option value="365">1 Year</option>
                            <option value="730">2 Years</option>
                        </select>
                    </div>
                    <Line data={data} options={options} />
                </CardBody>
            </Card>

    );
};

export default connect(state => ({
    priceTrackerData:state.product.priceTrackerData,
    productData:state.product.productData,
    priceHistoryRange: state.theme.priceHistoryRange,
    ratingHistoryRange: state.theme.ratingHistoryRange
}))(PriceHistory);

