import { createAction } from 'redux-actions';

export const setProduct = createAction('SET_PRODUCT');
export const setFeatures = createAction('SET_FEATURES');
export const setSpecifications = createAction('SET_SPECIFICATIONS');
export const setRatingTrackerData = createAction('SET_RATING_TRACKER_DATA');
export const setPriceTrackerData = createAction('SET_PRICE_TRACKER_DATA');
export const setLinks = createAction('SET_LINKS');
export const setCategories = createAction('SET_CATEGORIES');
export const setImages = createAction('SET_IMAGES');
export const setPriceExtended = createAction('SET_PRICE_EXTENDED');
export const setRatingExtended = createAction('SET_RATING_EXTENDED');
export const setCategoriesExtended = createAction('SET_CATEGORIES_EXTENDED');
