import React, {useEffect, useState} from 'react';
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ModelProducts from '../../../../Models/Products';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import {connect} from "react-redux";
import Panel from "../../../../shared/components/Panel";
import ProductsTable from "./components/ProductsTable";
import {showNotification} from "../../../../shared/components/Notification";
import ModalAddProduct from "./components/ModalAddProduct";
import LoadingIcon from 'mdi-react/LoadingIcon';

const ProductsWidget = ({ dispatch, loadedProducts,rtl,theme }) => {
    const { productId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const vm = new ModelProducts();


    // if(productData.vt_ids == null || productData.vt_ids.length === 0 || typeof productData.vt_ids === "undefined")
    //     return;

    const fetchData=()=>{
        return vm.getAllProducts().then(async response => {

            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }


            setIsLoaded(true);
            return true;
        });
    };

    useEffect(() => {
        // dispatch(showVenueMenu(true));
        fetchData();

        if(isLoaded){
            // Object.keys(productData).forEach(key => {
            //     document.querySelectorAll('input[name='+key+']').forEach((el)=>{
            //         el.value = productData[key];
            //     });
            //     document.querySelectorAll('textarea[name='+key+']').forEach((el)=>{
            //         el.value = productData[key];
            //     });
            // });
            // document.querySelector('input[type=checkbox][name=v_outdoor_space_available]').checked = productData.v_outdoor_space_available === 'Y';
            //
            //
            // let venueTypeIds = productData.vt_ids.split(",");
            // // document.querySelectorAll('input[type=checkbox][name*=vt_ids][value='+id.trim()+']').forEach((x)=>console.log(x))
            // venueTypeIds.forEach(function(id){
            //
            //     // debugger
            //     document.querySelectorAll('input[type=checkbox][name*=vt_ids]').forEach((vtId)=>{
            //
            //         if(vtId.value === id.trim())
            //             vtId.checked =true;
            //     });
            //     //.checked =true;
            //     // $('#ProductsWidgetForm [name*=vt_ids][value='+id.trim()+']').prop('checked', true);
            //
            //     // document.querySelectorAll('input[type=checkbox]');
            // });

        }



    },[]);





    return (


            <Container className="dashboard">
                <Row>
                    <Panel xl={12} lg={12} md={12} title={'Products'} refreshFunction={fetchData}>
                        {!isLoaded &&
                            <div className="panel__refresh"><LoadingIcon/></div>
                        }
                        <ModalAddProduct/>
                        {isLoaded &&
                            <ProductsTable/>
                        }

                    </Panel>
                </Row>
            </Container>

    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    loadedProducts:state.products.loadedProducts,
    // showVenueMenu:state.venue.showVenueMenu
}))(ProductsWidget);