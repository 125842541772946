import { handleActions } from 'redux-actions';
import {
    setProduct,
    setFeatures,
    setSpecifications,
    setRatingTrackerData,
    setPriceTrackerData,
    setLinks,
    setCategories,
    setImages,
    setPriceExtended,
    setRatingExtended,
    setCategoriesExtended
} from '../actions/productActions';

const defaultState = {
    productData: null,
    features: null,
    specifications: null,
    ratingTrackerData:null,
    priceTrackerData:null,
    links:null,
    categories:null,
    images:null,
    priceExtended:null,
    ratingExtended:null,
    categoriesExtended:null,

};

export default handleActions(
  {
      [setProduct](state, { payload }) {
          return { ...state, productData: payload };
      },[setFeatures](state, { payload }) {
          return { ...state, features: payload };
      },[setSpecifications](state, { payload }) {
          return { ...state, specifications: payload };
      },[setRatingTrackerData](state, { payload }) {
          return { ...state, ratingTrackerData: payload };
      },[setPriceTrackerData](state, { payload }) {
          return { ...state, priceTrackerData: payload };
      },[setLinks](state, { payload }) {
          return { ...state, links: payload };
      },[setCategories](state, { payload }) {
          return { ...state, categories: payload };
      },[setImages](state, { payload }) {
          return { ...state, images: payload };
      },[setPriceExtended](state, { payload }) {
          return { ...state, priceExtended: payload };
      },[setRatingExtended](state, { payload }) {
          return { ...state, ratingExtended: payload };
      },[setCategoriesExtended](state, { payload }) {
          return { ...state, categoriesExtended: payload };
      },
  },
  defaultState,
);
