import { handleActions } from 'redux-actions';
import {
    setCategory,
    setStatistics
} from '../actions/categoryActions';

const defaultState = {
    categoryData:null,
    statistics:null
};

export default handleActions(
  {
      [setCategory](state, { payload }) {
          return { ...state, categoryData: payload };
      },[setStatistics](state, { payload }) {
          return { ...state, statistics: payload };
      }
  },
  defaultState,
);
