import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarUnauthenticated from './TopbarUnauthenticated';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {

    let isAuthenticated = localStorage.getItem("isAuthenticated");

    useEffect(()=>{

    },[]);

    return (
        <div className="topbar">
            <div className="topbar__wrapper">
                <div className="topbar__left">
                    <TopbarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} changeSidebarVisibility={changeSidebarVisibility} />
                    <Link className="topbar__logo" to="/" />
                </div>
                <div className="topbar__right">
                    {/* ADD THE LOGIN AND REGISTER BUTTONS HERE*/}
                    {isAuthenticated==="Y" &&
                        <TopbarProfile />
                    }
                    {isAuthenticated!=="Y" &&
                        <TopbarUnauthenticated />
                    }

                </div>
            </div>
        </div>
    )};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
