import React,{ useState,useEffect } from 'react';
import { Card, CardBody,Col, Container, Row } from 'reactstrap';
import {useParams} from 'react-router-dom';
import ModelProduct from '../../Models/Products';
import ProductsWidget from "./components/ProductsWidget";

const Dashboard = () => {

    const { plink } = useParams();
    const mp = new ModelProduct();
    // const [pData,setData] = useState(null);
    const [isLoaded,setLoaded] = useState(false);

    useEffect(() => {
        console.log('rendering dah');

    },[]);

    return (
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Dashboard</h3>
                </Col>
            </Row>
            <Row>
                <ProductsWidget  />
            </Row>
        </Container>
    );
}

export default Dashboard;