import React,{ useState,useEffect } from 'react';
import { Card, CardBody,Col, Container, Row } from 'reactstrap';
import {useParams} from 'react-router-dom';
import ModelProduct from '../../Models/Products';
import ProductsWidget from "./components/ProductsWidget";
import FeaturedCategories from "../../shared/components/FeaturedCategories";

const Home = () => {

    const { plink } = useParams();
    const mp = new ModelProduct();
    // const [pData,setData] = useState(null);
    const [isLoaded,setLoaded] = useState(false);

    useEffect(() => {

    },[]);

    return (
        <Container className="Home">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Home</h3>
                </Col>
            </Row>
            <Row>
                <FeaturedCategories/>
            </Row>
            <Row>
                <ProductsWidget  />
            </Row>
        </Container>
    );
}

export default Home;