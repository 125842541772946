import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import Product from "../Product";
import ProductEdit from "../ProductEdit";
import Dashboard from "../Dashboard";
import Category from "../Category";
import Home from "../Home";
import ProtectedRoute from './ProtectedRoute';

const Pages = () => (
  <Switch>
    {/*<Route path="/pages/one" component={ExamplePageOne} />*/}
    {/*<Route path="/pages/two" component={ExamplePageTwo} />*/}
  </Switch>
);

const wrappedRoutes = () => (
    <div>
        <Layout />
        <div className="container__wrap">
            <Route exact path="/" component={Home} />
            <Route path="/pages" component={Pages} />
            <Route path="/products" component={Dashboard} />
            <Route exact path="/product/:plink" component={Product} />
            <Route exact path="/c/:plink" component={Category} />
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <ProtectedRoute path="/edit/:productId" component={ProductEdit} />
        </div>
    </div>
);

const protectedRoutes = () => (
    <div>
        <Layout />
        <div className="container__wrap">
            <Route exact path="/" component={Home} />
            <Route path="/pages" component={Pages} />
            <Route path="/products" component={Dashboard} />
            <Route exact path="/product/:plink" component={Product} />
            <Route exact path="/c/:plink" component={Category} />
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <ProtectedRoute path="/edit/:productId" component={ProductEdit} />
        </div>
    </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/login" component={LogIn} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;